<template>
  <div class="container mt-5   px-md-5" style="margin-left:auto; margin-right:auto;">
      
      <div class=" mb-4">
          <h2 class="text-left"  style="text-transform: capitalize;">{{table}}</h2>
      </div>

    <div class="container-fluid p-0" v-if="usuario.rol == 99">
      <form @submit.prevent="SendData()">
        <div class="row py-3">
          <div class="col-md-11 p-md-1">
            <div class="row">
              <div class="col-md-4 pr-3 py-1">
                <b-form-select @change="getSubcategorias" text-field="nombre" value-field="id" required v-model="newRow.categoria_id" class="form-control" placeholder="Categoría" :options="categorias" >
                  <template #first>
                    <b-form-select-option selected :value="null" disabled>-- Please select an option --</b-form-select-option>
                  </template>
                </b-form-select>
              </div>
              <div class="col-md-4 pr-3 py-1">
                <b-form-select  @change="getProductos" text-field="titulo" value-field="id" required v-model="newRow.subcategoria_id" class="form-control" placeholder="Categoría" :options="subcategorias"></b-form-select>
              </div>
              <div class="col-md-4 pr-3 py-1">
                <b-form-select text-field="titulo" value-field="id" required v-model="newRow.producto_id" class="form-control" placeholder="Categoría" :options="productos"></b-form-select>
              </div>
            </div>

           
            <div class="row mt-2">
              <!-- <div class="col-md-4 pr-3 py-1">
                <input required type="number" v-model="newRow.clave" class="form-control" id="exampleInputPassword1" placeholder="Clave del producto">
              </div> -->
              
              <div class="col-md-12 pr-3 py-1">
                <input  required type="number" v-model="newRow.personas" class="form-control" id="exampleInputPassword1" placeholder="Personas">
              </div>
            </div>
          </div>
        </div>
        <button type="submit" class="btn-primary mb-5">Cotizar ahora</button>
      </form>
    </div>

    <div v-if="usuario.rol == 99" class="my-4 mb-2">
      <h2 class="text-left">Mis solicitudes</h2>
    </div>
    
    
    <div class="bg-gray ">
      <b-table 
          hover
          responsive 
          :items="table_list" 
          :fields="fields" 
          :current-page="currentPage" 
          :per-page="perPage" 
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
      >
      <template #cell(fecha)="data">
          <span  >{{ data.item.fecha != null ? $moment(data.item.fecha).utc().format("DD-MM-YYYY") : '' }}</span>
      </template>
      <template #cell(name)="row">
          {{ row.value.first }} {{ row.value.last }}
      </template>
        <template #cell(action)="data" v-if="usuario.rol != 99">
            <button @click="showModal(2, data.item)" class="btn-white-outline-small mx-1"><i class="fa fa-pen"></i></button>
            <button @click="deleteItem(data.item)" class="btn-primary-small mx-1"><i class="fa fa-trash"></i></button>

        </template>
      <template #row-details="row">
          <b-card>
          <ul>
              <li v-for="(value, key) in row.item" :key="key">{{ key }}: {{ value }}</li>
          </ul>

          </b-card>
      </template>
      </b-table>
        <div>
          
      </div>
    </div>
    <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" hide-goto-end-buttons hide-ellipsis prev-text="Prev" next-text="Next" align="right"></b-pagination>

    <b-modal ref="modal-item2" hide-footer >
      <template #modal-title>
        Cotización
      </template>

      <template>
        <form @submit.prevent="updateData()" style="padding:10px">
          <div class="row">
            <div class="col-12">
              <label for="">Nombre</label>
              <input readonly class="form-control" v-model="row.nombre" type="text" name="" id="">
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <label for="">Email</label>
              <input readonly class="form-control" v-model="row.email" type="text" name="" id="">
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <label for="">Teléfono</label>
              <input readonly class="form-control" v-model="row.telefono" type="text" name="" id="">
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <label for="">Agencia</label>
              <input readonly class="form-control" v-model="row.agencia" type="text" name="" id="">
            </div>
          </div>
          <div class="row mt-1">
            <div class="col-12">
              <label for="">Producto</label>
              <input readonly class="form-control" v-model="row.producto" type="text" name="" id="">
            </div>
          </div>
          <div class="row mt-1">
            <div class="col-12">
              <label for="">Estado</label>
                <b-form-select :options="estados" text-field="titulo" value-field="titulo" 
                required v-model="row.estado" class="mb-3 form-control">
                    <!-- <b-form-select-option class="text-center" v-for="item of Estados" 
                    : :value="item.titulo" :key="item.titulo">{{ item.titulo }} </b-form-select-option> -->
                </b-form-select>            
            </div>
          </div>
          <div class="row mt-1">
            <div class="col-12">
              <label for="">Personas</label>
              <input readonly class="form-control" v-model="row.personas" type="text" name="" id="">
            </div>
          </div>

          <div class="row mt-4">
            <div class="col-12">
              <b-button type="submit" class="btn-primary-small mx-1 float-right ml-3">Guardar</b-button>
              <b-button @click="$refs['modal-item2'].hide()" class="btn-white-outline-small float-right">Cancelar</b-button>
            </div>
          </div>
        </form>
      </template>
    </b-modal>     

    <!-- enviar cotización -->

        <b-modal ref="modal-item3" hide-footer >
      <template #modal-title>
        Enviar Cotización
      </template>

      <template>
        <form @submit.prevent="SendData()" style="padding:10px">
          <div class="row">
            <div class="col-12">
              <label for="">Nombre</label>
              <input class="form-control" v-model="newRow.nombre" type="text" name="" id="">
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <label for="">Email</label>
              <input class="form-control" v-model="newRow.email" type="email" name="" id="">
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <label for="">Teléfono</label>
              <input class="form-control" v-model="newRow.telefono" type="text" name="" id="">
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <label for="">Agencia</label>
              <input class="form-control" v-model="newRow.agencia" type="text" name="" id="">
            </div>
          </div>

          <div class="row mt-4">
            <div class="col-12">
              <b-button type="submit" class="btn-primary-small mx-1 float-right ml-3">Guardar</b-button>
              <b-button @click="$refs['modal-item3'].hide()" class="btn-white-outline-small float-right">Cancelar</b-button>
            </div>
          </div>
        </form>
      </template>
    </b-modal>    



  </div>
</template>

<script>
// import FormVue from "@/components/Form.vue";
export default {
  components: {
    // FormVue,
  },
  // computed: {
  //   rows() {
  //     return this.items.length;
  //   },
  // },
  data() {
    return {
      selected: null,
      categorias: [],
      subcategorias: [],
      productos: [],
      perPage: 25,
      currentPage: 1,
      filter: null,

      table: "cotizaciones",
      fields: [
        { key: "id", label: "Código" },
        { key: "name", label: "Nombre" },
        { key: "rolId", label: "rol" },
        { key: "username", label: "usuario" },
        { key: "storeId", label: "tienda" },
        { key: "actions", label: "Acciones" },
      ],
      items: [],
      roles:[],
      subRoles: [],
      stores:[],
      selectedItem: {},

      showDetails: false,

      formFields: [
        { label: "Nombre", key: "name", required: true, value: "" },
        { label: "Rol", key: "rolId", required: true, value: "" },
        { label: "Usuario", key: "username", required: true, value: "" },
        { label: "Contraseña", key: "password", required: true, value: "" },
        { label: "Tienda", key: "storeId", required: true, value: "" },
      ],
      method: "post",
      table_list: [
        { id: 1 , fecha: "10-09-2021", nombre: "Pablo Perez", producto: "EUR-962365", estado: "Pendiente" },
        { id: 2 , fecha: "10-09-2021", nombre: "Pablo Perez", producto: "EUR-962365", estado: "Pendiente" },
        { id: 3 , fecha: "10-09-2021", nombre: "Pablo Perez", producto: "EUR-962365", estado: "Pendiente" },
        { id: 4 , fecha: "10-09-2021", nombre: "Pablo Perez", producto: "EUR-962365", estado: "Pendiente" },
        { id: 5 , fecha: "10-09-2021", nombre: "Pablo Perez", producto: "EUR-962365", estado: "Pendiente" },
        
      ]
      , usuario: {},
      row:{},
      newRow:{},

      ////////
      fields: [
          { key: 'id', label: 'id', sortable: true, sortDirection: 'desc', class: 'text-center' },
          { key: 'fecha', label: 'Fecha', sortable: true, class: 'text-center' },
          { key: 'nombre', label: 'Nombre', sortable: true, class: 'text-center' },
          { key: 'producto', label: 'Producto', sortable: true, class: 'text-center' },
          { key: 'estado', label: 'Estado', sortable: true, class: 'text-center' },

          { key: 'action', label: 'Acciones',class: 'text-center'},

        {
          sortable: true,
          sortByFormatted: true,
          filterByFormatted: true
        },
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, { value: 100, text: 100 }],
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      estados:[
        {id:0, titulo: 'Aceptada'},
        {id:1, titulo: 'Pendiente'},
        {id:2, titulo: 'Rechazada'},
      ],
      id:0,
      resParam: null

    };
  },
  async mounted() {
    try {
      
      this.id = this.$route.params.id
      this.usuario = JSON.parse(localStorage.getItem('user')) || {}
      console.log(this.usuario)
      this.getData();
      if(this.id>0){
        this.$isLoading(true)
        //obtener categoria , subcategoria y producto
        this.resParam = await this.$store.dispatch("get", { path: 'tarjetas/getAll/' + this.id});
        console.log('resparam ',this.resParam)
        this.newRow.categoria_id = this.resParam[0].categoria_id
        await this.getSubcategorias()
        this.newRow.subcategoria_id = this.resParam[0].subcategoria_id
        await this.getProductos()
        this.newRow.producto_id = this.id
        this.$isLoading(false)
      }
      await this.getcategorias()
      //await this.getSubcategorias()
    } catch (error) {
      console.log('error', error)
    }
  },
  methods: {
    showModal(i,item){
      this.row = item
      this.$refs['modal-item2'].show()
    },
    async getProductos() {
      console.log(this.newRow.subcategoria_id)
      try {
        let res = await this.$store.dispatch("get", { path: "tarjetas/getAllBySubcategory/" + this.newRow.subcategoria_id});
        console.log(res)
        if(res.length>0){
          this.productos = res
        }
        else{
          this.productos = []
        }
      } catch (error) {
        this.productos = []
        alert(error);
      }
    },
    async getSubcategorias() {
      console.log(this.newRow.categoria_id)
      try {
        let res = await this.$store.dispatch("get", { path: "subcategorias/getAllByCategory/" + this.newRow.categoria_id});
        console.log(res)
        if(res.length>0){
          //res.push()
          this.subcategorias = res
        }
        else{
          this.subcategorias = []
        }
      } catch (error) {
        this.subcategorias = []
        alert(error);
      }
    },
    async getcategorias() {
      try {
        let res = await this.$store.dispatch("get", { path: "categorias/getAll/"});
        console.log(res)
        if(res.length>0){
          //res.push({id: null, nombre: 'Categorías', disabled: true, selected: true })
          this.categorias = res
          let iid = this.categorias.findIndex((x) => x.id == 6)
          this.categorias.splice(iid,1)
          console.log('-------',iid)
          this.totalRows = this.categorias.length
        }
        else{
          this.categorias = []
          this.totalRows = this.categorias.length
        }
      } catch (error) {
        this.categorias = []
        this.totalRows = this.categorias.length
        alert(error);
      }
    },
    async getData() {
      try {
        this.$isLoading(true)
        let ruta
        if(this.usuario.rol==1){
          ruta = this.table+'/getAll'
        }
        else{
          ruta = this.table + '/getAllByUser/' + this.usuario.id
        }
        let res = await this.$store.dispatch("get", { path: ruta });
        this.$isLoading(false)        
        console.log(res)
        if(res.length>0){
          this.table_list = res
          this.totalRows = this.table_list.length
        }
        else{
          this.table_list = []
          this.totalRows = this.table_list.length
        }
      } catch (error) {
        this.$isLoading(false)        
        this.table_list = []
        this.totalRows = this.table_list.length
        alert(error);
      }
    },
    async SendData() {
      try {
        this.$isLoading(true)
        this.newRow.cliente_id = this.usuario.id
        this.newRow.fecha = this.$moment().utc().format('YYYY-MM-DD hh:mm:ss')
        this.newRow.estado = 'Pendiente'
        let req = await this.$store.dispatch("post", {
          path: 'cotizaciones/new/',
          data: this.newRow
        });
        //enviar notificación
        req = await this.$store.dispatch("post", {
          path: 'enviarCorreo/cotizacion/',
          data: this.newRow
        });

        this.$isLoading(false)        
        this.$refs['modal-item3'].hide()
        this.$swal({
          icon: "success",
          title: "Muy bien!",
          text: "Datos guardados correctamente!",
        });        
        this.getData();
      } catch (error) {
        this.$isLoading(false)        
        this.$swal({
          icon: "error",
          title: "Oopss!",
          text: "Error:" + error,
        });                
        
      }
    },
    async updateData() {
      try {
        this.$isLoading(true)
        let data = {
          estado: this.row.estado
        }
        let req = await this.$store.dispatch("put", {
          path: 'cotizaciones/modify/' + this.row.id,
          data: data
        });
        
        this.$isLoading(false)        
        this.$refs['modal-item2'].hide()
        this.$swal({
          icon: "success",
          title: "Muy bien!",
          text: "Datos guardados correctamente!",
        });        
        this.getData();
      } catch (error) {
        this.$isLoading(false)        
        this.$swal({
          icon: "error",
          title: "Oopss!",
          text: "Error:" + error,
        });                
        
      }
    },


    gotoTanks(){
      console.log('voy a ...')
      this.$router.push('/admin/gracias_solicitud')
    },

    /////////////
    async deleteItem( item ) {
      try {
        const sure = await confirm(
          "¿Está seguro que desea eliminar este elemento?"
        );
        if (!sure) {
          return;
        }

        const req = await this.$store.dispatch("deleteItem", {
          table: this.table,
          id: item.id,
        });
        this.getData();
      } catch (error) {
        alert(error);
      }
    },

  }
  
};
</script>

<style>

 

</style>